import { lazy } from "react";

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      return component;
    } catch (error) {
      throw error;
    }
  });

export const routes = [
  {
    path: "/",
    name: "HomePage",
    Component: lazyWithRetry(() => import("./HomePage")),
    type: "Auth",
  },
  {
    path: "/kpi",
    name: "KPI",
    Component: lazyWithRetry(() => import("./KPIPage")),
    type: "Auth",
  },
  {
    path: "/login",
    name: "LoginPage",
    Component: lazyWithRetry(() => import("./LoginPage")),
  },
  {
    path: "/sehty-doctors",
    name: "SehtyPage",
    Component: lazyWithRetry(() => import("./SehtyDoctorsPage")),
    type: "Auth",
  },
  {
    path: "/sehty-doctors/new",
    name: "FormSehtyDoctorPage",
    Component: lazyWithRetry(() => import("./FormSehtyDoctorPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/sehty-doctors/:doctorId/edit",
    name: "FormSehtyDoctorPage",
    Component: lazyWithRetry(() => import("./FormSehtyDoctorPage")),
    edit: true,
    type: "Auth",
  },
  // {
  //   path: "/employees",
  //   name: "Employees",
  //   Component: lazyWithRetry(() => import("./EmployeesPage")),
  //   type: "Auth",
  // },
  // {
  //   path: "/employees/new",
  //   name: "Employees",
  //   Component: lazyWithRetry(() => import("./FormEmployeesPage")),
  //   edit: false,
  //   type: "Auth",
  // },
  // {
  //   path: "/employees-salary/new",
  //   name: "FormEmployeesSalaryPage",
  //   Component: lazyWithRetry(() => import("./FormEmployeesSalaryPage")),
  //   type: "Auth",
  // },
  // {
  //   path: "/reports",
  //   name: "ReportsPage",
  //   Component: lazyWithRetry(() => import("./ReportsPage")),
  // },
  {
    path: "/doctors",
    name: "DoctorsPage",
    Component: lazyWithRetry(() => import("./DoctorsPage")),
    type: "Auth",
  },
  {
    path: "/sur-doctors",
    name: "SurvDoctorsPage",
    Component: lazyWithRetry(() => import("./SurvDoctorsPage")),
    type: "Auth",
  },
  {
    path: "/doctors/:doctorId/edit",
    name: "FormDoctorPage",
    Component: lazyWithRetry(() => import("./FormDoctorPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/doctors/:doctorId/new-task",
    name: "./FormTaskPage",
    Component: lazyWithRetry(() => import("././FormTaskPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/sur-doctors/new",
    name: "FormSurveyDoctorPage",
    Component: lazyWithRetry(() => import("./FormSurveyDoctorPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/sur-doctors/:doctorId/edit",
    name: "FormSurveyDoctorPage",
    Component: lazyWithRetry(() => import("./FormSurveyDoctorPage")),
    edit: true,
    type: "Auth",
  },
  // {
  //   path: "/doctors/:id/reports",
  //   name: "ReportsPage",
  //   Component: lazyWithRetry(() => import("./ReportsPage")),
  //   edit: false,
  //   type: "Auth",
  // },
  {
    path: "/users",
    name: "UsersPage",
    Component: lazyWithRetry(() => import("./UsersPage")),
    type: "Auth",
  },
  {
    path: "/users/:userId/edit",
    name: "FormUserPage",
    Component: lazyWithRetry(() => import("./FormUserPage")),
    edit: true,
    type: "Auth",
  },
  // {
  //   path: "/inspectors/:id/reports",
  //   name: "ReportsPage",
  //   Component: lazyWithRetry(() => import("./ReportsPage")),
  //   edit: false,
  //   type: "Auth",
  // },
  {
    path: "/users/new",
    name: "FormUserPage",
    Component: lazyWithRetry(() => import("./FormUserPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/tasks",
    name: "TasksPage",
    Component: lazyWithRetry(() => import("./TasksPage")),
    type: "Auth",
  },
  {
    path: "/tasks/new",
    name: "FormTaskPage",
    Component: lazyWithRetry(() => import("./FormTaskPage")),
    type: "Auth",
  },
  {
    path: "/tasks/:taskId/edit",
    name: "FormTaskPage",
    Component: lazyWithRetry(() => import("./FormTaskPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/visits",
    name: "VisitsPage",
    Component: lazyWithRetry(() => import("./VisitsPage")),
    type: "Auth",
  },
  {
    path: "/visits/:visitId/edit",
    name: "FormVisitPage",
    Component: lazyWithRetry(() => import("./FormVisitPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/surv-visits",
    name: "SurvVisitsPage",
    Component: lazyWithRetry(() => import("./SurvVisitsPage")),
    type: "Auth",
  },
  {
    path: "/surv-visits/:visitId/edit",
    name: "FormVisitPage",
    Component: lazyWithRetry(() => import("./FormVisitPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/reviews",
    name: "ReviewsPage",
    Component: lazyWithRetry(() => import("./ReviewsPage")),
    type: "Auth",
  },
];
